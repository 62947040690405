<template>
  <div class="px-6 py-4 flex flex-col p-6">
    <BPageLoader v-if="isLoading && !data.fundraisers?.length" />
    <template v-else-if="data.fundraisers?.length">
      <div class="grid grid-cols-2 sm:grid-cols-3 gap-4">
        <BFundraiserCardSM
          v-for="fundraiser in data.fundraisers"
          :key="fundraiser.id"
          :fundraiser="fundraiser"
          @like="fundraiser.likes = $event"
        />
      </div>
      <BPageLoader class="my-2" v-if="isLoading" />
    </template>
    <div
      class="flex flex-grow flex-col items-center justify-center py-10 text-text-primary"
      v-else
    >
      <span class="p-6 bg-bg-alternate-3 rounded-full">
        <ProfileIcon class="h-10 stroke-current stroke-2 text-text-alternate-4" />
      </span>
      <h4 class="font-semibold text-md mt-8 mb-3">No Fundraiser</h4>
      <p class="text-xs font-medium text-center" v-if="isOwner">
        You are not following anyone
      </p>
      <p class="text-xs font-medium text-center" v-else>
        This user isn’t following anyone
      </p>
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import BFundraiserCardSM from '@/components/ui/BFundraiserCardSM';
import { useApi } from '@/cmp-functions/useApi';
import { getFollowedFundraiser } from '@/services/api';
import { useInfiniteScroll } from '@/cmp-functions/useInfiniteScroll';

const handlePaginationResponse = (pre, { data }) => {
  if (!data?.data) return pre;
  const { data: fundraisers, metadata } = data;

  return {
    fundraisers: [...pre.fundraisers, ...fundraisers],
    metaData: {
      offset: fundraisers.length + pre.fundraisers.length,
      ...metadata,
    },
  };
};

export default {
  name: 'Following',

  components: { BFundraiserCardSM },

  props: ['profileId', 'isOwner'],

  setup(props) {
    const [response, fetchFollowedFundraiser] = useApi(
      getFollowedFundraiser,
      {
        fundraisers: [],
        metaData: { offset: 0, isLastPage: false },
      },
      handlePaginationResponse
    );

    const fetchFundraiserData = () => {
      if (response.data.metaData.isLastPage || response.isLoading) return;
      fetchFollowedFundraiser(props.profileId, response.data.metaData.offset);
    };

    useInfiniteScroll(fetchFundraiserData, true);

    return {
      ...toRefs(response),
    };
  },
};
</script>
