<template>
  <div>
    <div class="lg:hidden">
      <div class="py-2 flex flex-col font-semibold">
    <BPageLoader v-if="isLoading" class="flex-grow" />
    <template v-else-if="isSuccess">
      <header class="px-6 py-2 text-lg flex text-text-alternate-1 pr-12">
        <button class="focus:outline-none" @click="$router.go(-1)">
          <LeftArrowIcon class="h-6 fill-current" />
        </button>
        <h2 class="mx-auto">Profile</h2>
      </header>
      <section class="mx-6 flex flex-col justify-between items-center pt-8 pb-6">
        <div
          class="h-20 w-20 flex items-center justify-center rounded-full font-bold relative"
          :style="{
            backgroundColor: randomColor({
              luminosity: 'bright',
              seed: `${data.firstname[0]}${data.lastname[0]}`,
            }),
            color: '#fff',
          }"
        >
          <img :src="data.profilePhoto" v-if="data.profilePhoto" class="rounded-full" />
          <span v-else>{{ data.firstname[0] }}{{ data.lastname[0] }}</span>
          <BadgeIcon class="h-6 absolute -right-1 bottom-2" style="fill: #e0f2ff" />
        </div>
        <span class="text-sm text-text-alternate-1 mt-2 mb-1">
          {{ data.firstname }} {{ data.lastname }}
        </span>
        <span
          :class="[
            'text-xs text-text-alternate-3 flex items-center',
            { 'pl-3': data.verificationStatus !== 'UNVERIFIED' },
          ]"
        >
          @{{ data.username }}
          <VerifiedIcon class="h-4 pl-2" v-if="data.verificationStatus === 'VERIFIED'" />
          <VIPIcon class="h-5 pl-2" v-else-if="data.verificationStatus === 'VIP'" />
        </span>
        <template v-if="authUser">
          <router-link
            class="border text-center rounded-3xl mt-4 py-2 w-28 font-bold text-2xs"
            v-if="isOwner"
            :to="{ name: 'EditProfile', params: { username: data.username } }"
          >
            Edit Profile
          </router-link>
          <button
            v-else-if="data.isFollowing"
            class="
              text-btn-text-primary
              bg-btn-bg-primary
              focus:outline-none
              rounded-3xl
              mt-4
              h-8
              w-28
              font-bold
              text-2xs
            "
            @click="showModal = true"
            :disabled="unFollowResponse.isLoading"
          >
            <BButtonLoader class="h-4 w-4 mx-auto" v-if="unFollowResponse.isLoading" />
            <span v-else>Following</span>
          </button>
          <button
            v-else
            class="border focus:outline-none rounded-3xl mt-4 h-8 w-28 font-bold text-2xs"
            @click="follow"
            :disabled="followResponse.isLoading"
          >
            <BButtonLoader class="h-4 w-4 mx-auto" v-if="followResponse.isLoading" />
            <span v-else>Follow</span>
          </button>
        </template>
      </section>
      <section class="mx-6">
        <div
          class="py-2.5 border-t border-border-primary text-xs flex"
          v-if="socialLinks.length || isOwner"
        >
          <p class="border-r border-border-primary w-1/2 py-1.5" v-if="isOwner">
            <span class="font-medium text-text-alternate-3">User ID: </span>
            <span class="text-text-alternate-1">{{ data.userid }}</span>
          </p>
          <p
            :class="[
              'flex gap-3 xs:gap-4 items-center',
              isOwner ? 'w-1/2 pl-5' : 'w-full justify-center',
            ]"
          >
            <template v-for="{ smname, link, id } in socialLinks" :key="id">
              <a :href="link" target="_blank" v-if="link">
                <FBIcon class="h-6" v-if="smname === 'Facebook'" />
                <IGIcon class="h-6" v-if="smname === 'Instagram'" />
                <TWIcon class="h-6" v-if="smname === 'Twitter'" />
                <YTIcon class="h-6" v-if="smname === 'Youtube'" />
                <TKKIcon class="h-6" v-if="smname === 'Tiktok'" />
                <TLGIcon class="h-6" v-if="smname === 'Telegram'" />
                <WEBIcon class="h-6" v-if="smname === 'Web'" />
              </a>
            </template>
          </p>
        </div>
        <p class="text-xs pt-4 pb-8 font-normal border-t border-border-primary" v-if="data.bio">
          {{ data.bio }}
        </p>
      </section>
      <section>
        <header class="overflow-scroll bg-bg-alternate-3 px-6">
          <ul class="inline-flex text-xs xs:text-sm justify-between w-full">
            <li v-for="(nav, index) in tabs" :key="index">
              <button
                :class="[
                  'px-4 py-3 font-semibold nav-border focus:outline-none',
                  active === nav ? 'nav-active text-text-alternate-1' : 'text-text-alternate-10',
                ]"
                @click="active = nav"
              >
                {{ nav }}
              </button>
            </li>
          </ul>
        </header>
        <Fundraisers v-if="active === 'Fundraisers'" :profileId="data.userid" :isOwner="isOwner" />
        <Following v-else-if="active === 'Following'" :profileId="data.userid" :isOwner="isOwner" />
        <Saved v-else-if="active === 'Saved'" :profileId="data.userid" :isOwner="isOwner" />
        <Badges v-else-if="active === 'Badges'" :badgeNumber="badgeNumber" />
      </section>
    </template>
    <div class="text-center flex flex-col items-center p-6 pt-14" v-else>
      <FailureIcon class="my-4 mx-auto" />
      <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
      <p class="text-xs xs:text-sm text-alternate-3 font-normal">
        We cannot find the page you are looking for
      </p>
      <div class="flex flex-col w-full max-w-sm mt-6">
        <router-link
          class="
            bg-btn-bg-primary
            text-btn-text-primary
            my-2
            xs:my-3
            py-4
            rounded
            text-center text-sm
            xs:text-base
            font-bold
          "
          :to="{ name: 'Feeds' }"
        >
          Take me to Feed
        </router-link>
      </div>
    </div>
  </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 px- pt-8">
        <div class="py-2 flex flex-col font-semibold ">
    <BPageLoader v-if="isLoading" class="flex-grow" />
    <template v-else-if="isSuccess">
      <header class="px-6 py-2 text-lg flex text-text-alternate-1 pr-12">
        <button class="focus:outline-none" @click="$router.go(-1)">
          <LeftArrowIcon class="h-6 fill-current" />
        </button>
        <h2 class="mx-auto">Profile</h2>
      </header>
      <section class="mx-6 flex flex-col justify-between items-center pt-8 pb-6">
        <div
          class="h-20 w-20 flex items-center justify-center rounded-full font-bold relative"
          :style="{
            backgroundColor: randomColor({
              luminosity: 'bright',
              seed: `${data.firstname[0]}${data.lastname[0]}`,
            }),
            color: '#fff',
          }"
        >
          <img :src="data.profilePhoto" v-if="data.profilePhoto" class="rounded-full" />
          <span v-else>{{ data.firstname[0] }}{{ data.lastname[0] }}</span>
          <BadgeIcon class="h-6 absolute -right-1 bottom-2" style="fill: #e0f2ff" />
        </div>
        <span class="text-sm text-text-alternate-1 mt-2 mb-1">
          {{ data.firstname }} {{ data.lastname }}
        </span>
        <span
          :class="[
            'text-xs text-text-alternate-3 flex items-center',
            { 'pl-3': data.verificationStatus !== 'UNVERIFIED' },
          ]"
        >
          @{{ data.username }}
          <VerifiedIcon class="h-4 pl-2" v-if="data.verificationStatus === 'VERIFIED'" />
          <VIPIcon class="h-5 pl-2" v-else-if="data.verificationStatus === 'VIP'" />
        </span>
        <template v-if="authUser">
          <router-link
            class="border text-center rounded-3xl mt-4 py-2 w-28 font-bold text-2xs"
            v-if="isOwner"
            :to="{ name: 'EditProfile', params: { username: data.username } }"
          >
            Edit Profile
          </router-link>
          <button
            v-else-if="data.isFollowing"
            class="
              text-btn-text-primary
              bg-btn-bg-primary
              focus:outline-none
              rounded-3xl
              mt-4
              h-8
              w-28
              font-bold
              text-2xs
            "
            @click="showModal = true"
            :disabled="unFollowResponse.isLoading"
          >
            <BButtonLoader class="h-4 w-4 mx-auto" v-if="unFollowResponse.isLoading" />
            <span v-else>Following</span>
          </button>
          <button
            v-else
            class="border focus:outline-none rounded-3xl mt-4 h-8 w-28 font-bold text-2xs"
            @click="follow"
            :disabled="followResponse.isLoading"
          >
            <BButtonLoader class="h-4 w-4 mx-auto" v-if="followResponse.isLoading" />
            <span v-else>Follow</span>
          </button>
        </template>
      </section>
      <section class="mx-6">
        <div
          class="py-2.5 border-t border-border-primary text-xs flex"
          v-if="socialLinks.length || isOwner"
        >
          <p class="border-r border-border-primary w-1/2 py-1.5" v-if="isOwner">
            <span class="font-medium text-text-alternate-3">User ID: </span>
            <span class="text-text-alternate-1">{{ data.userid }}</span>
          </p>
          <p
            :class="[
              'flex gap-3 xs:gap-4 items-center',
              isOwner ? 'w-1/2 pl-5' : 'w-full justify-center',
            ]"
          >
            <template v-for="{ smname, link, id } in socialLinks" :key="id">
              <a :href="link" target="_blank" v-if="link">
                <FBIcon class="h-6" v-if="smname === 'Facebook'" />
                <IGIcon class="h-6" v-if="smname === 'Instagram'" />
                <TWIcon class="h-6" v-if="smname === 'Twitter'" />
                <YTIcon class="h-6" v-if="smname === 'Youtube'" />
                <TKKIcon class="h-6" v-if="smname === 'Tiktok'" />
                <TLGIcon class="h-6" v-if="smname === 'Telegram'" />
                <WEBIcon class="h-6" v-if="smname === 'Web'" />
              </a>
            </template>
          </p>
        </div>
        <p class="text-xs pt-4 pb-8 font-normal border-t border-border-primary" v-if="data.bio">
          {{ data.bio }}
        </p>
      </section>
      <section>
        <header class="overflow-scroll bg-bg-alternate-3 px-6">
          <ul class="inline-flex text-xs xs:text-sm justify-between w-full">
            <li v-for="(nav, index) in tabs" :key="index">
              <button
                :class="[
                  'px-4 py-3 font-semibold nav-border focus:outline-none',
                  active === nav ? 'nav-active text-text-alternate-1' : 'text-text-alternate-10',
                ]"
                @click="active = nav"
              >
                {{ nav }}
              </button>
            </li>
          </ul>
        </header>
        <Fundraisers v-if="active === 'Fundraisers'" :profileId="data.userid" :isOwner="isOwner" />
        <Following v-else-if="active === 'Following'" :profileId="data.userid" :isOwner="isOwner" />
        <Saved v-else-if="active === 'Saved'" :profileId="data.userid" :isOwner="isOwner" />
        <Badges v-else-if="active === 'Badges'" :badgeNumber="badgeNumber" />
      </section>
    </template>
    <div class="text-center flex flex-col items-center p-6 pt-14" v-else>
      <FailureIcon class="my-4 mx-auto" />
      <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
      <p class="text-xs xs:text-sm text-alternate-3 font-normal">
        We cannot find the page you are looking for
      </p>
      <div class="flex flex-col w-full max-w-sm mt-6">
        <router-link
          class="
            bg-btn-bg-primary
            text-btn-text-primary
            my-2
            xs:my-3
            py-4
            rounded
            text-center text-sm
            xs:text-base
            font-bold
          "
          :to="{ name: 'Feeds' }"
        >
          Take me to Feed
        </router-link>
      </div>
    </div>
  </div>
      </div>
    </div>
  </div>
  <BModal :isOpen="showModal" @close="showModal = false">
    <div class="flex flex-col items-center pb-6">
      <h4 class="text-text-primary font-semibold my-4">Unfollow this organiser?</h4>
      <p class="text-center font-medium text-xs text-text-alternate-3">
        Unfollowing an organiser or user means that you won’t be able to see updates on fundraisers
        created by them.
      </p>
      <button
        class="
          font-bold
          mx-auto
          w-full
          mt-16
          mb-6
          py-4
          xs:py-5
          text-sm text-center text-btn-text-primary
          bg-btn-bg-primary
          inline-block
          rounded
        "
        :disabled="unFollowResponse.isLoading"
        @click="unFollow"
      >
        <BButtonLoader class="h-4 w-4 mx-auto" v-if="unFollowResponse.isLoading" />
        <span v-else>Unfollow</span>
      </button>
      <button
        class="text-text-primary focus:outline-none mt-4 rounded text-center text-sm font-bold"
        @click="showModal = false"
      >
        Cancel
      </button>
    </div>
  </BModal>
</template>

<script>
import { ref, toRefs, computed, watch } from 'vue';
import Fundraisers from './Fundraisers';
import Following from './Following';
import Badges from './Badges';
import Saved from './Saved';
import { useApi } from '@/cmp-functions/useApi';
import { getUserProfileByUsername, unFollowUser, followUser } from '@/services/api';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import randomColor from 'randomcolor';
import BModal from '@/components/ui/BModal';
import { useToast } from 'vue-toastification';

export default {
  name: 'Profile',

  components: { Fundraisers, Following, Saved, Badges, BModal },

  setup() {
    const store = useStore();
    const route = useRoute();
    const toast = useToast();
    const [response, fetchUserProfile] = useApi(getUserProfileByUsername);
    watch(
      () => route.params.username,
      (newId, oldId) => {
        const shouldFetch = newId && newId !== oldId;
        shouldFetch && fetchUserProfile(newId);
      },
      { immediate: true }
    );

    const badgeNumber = computed(() => response.data?.badges?.split(',')?.length);

    const active = ref('Fundraisers');
    const showModal = ref(false);

    const authUser = computed(() => store.state.auth.authData);
    const isOwner = computed(() => response.data.userid == authUser.value?.id);

    const tabs = computed(() => {
      // if (!isOwner.value) return ['Fundraisers', 'Following', 'Badges'];
      // return ['Fundraisers', 'Following', 'Saved', 'Badges'];
      if (!isOwner.value) return ['Fundraisers', 'Following'];
      return ['Fundraisers', 'Following', 'Saved'];
    });

    const [unFollowResponse, unFollowProfile] = useApi(unFollowUser);
    const [followResponse, followProfile] = useApi(followUser);

    const unFollow = () => {
      unFollowProfile({ accountId: response.data.userid, userId: authUser.value?.id });
    };

    watch(unFollowResponse, ({ isSuccess }) => {
      if (isSuccess) {
        response.data.isFollowing = false;
        showModal.value = false;
        toast.success('Organizer unfollowed!');
      }
    });

    const socialLinks = computed(() =>
      response.data.sociallinks.filter(({ link }) => link?.trim())
    );

    const follow = () => {
      followProfile({ accountId: response.data.userid, userId: authUser.value?.id });
    };

    watch(followResponse, ({ isSuccess }) => {
      if (isSuccess) {
        response.data.isFollowing = true;
        toast.success('Organizer followed!');
      }
    });

    return {
      tabs,
      showModal,
      badgeNumber,
      authUser,
      active,
      unFollow,
      follow,
      isOwner,
      ...toRefs(response),
      unFollowResponse,
      followResponse,
      randomColor,
      socialLinks,
    };
  },
};
</script>

<style lang="scss" scoped>
.nav-border {
  border: 4px solid transparent;
}

.nav-active {
  border-bottom-color: #577387;
}
</style>
