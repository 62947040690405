<template>
  <div class="px-6 py-4 flex flex-col p-6">
    <BPageLoader v-if="isLoading && !data.fundraisers?.length" />
    <template v-else-if="data.fundraisers?.length">
      <div class="grid grid-cols-2 sm:grid-cols-3 gap-4">
        <BFundraiserCardSM
          v-for="(fundraiser, index) in data.fundraisers"
          :key="fundraiser.id"
          :fundraiser="fundraiser"
          @like="remove(index)"
        />
      </div>

      <BPageLoader class="my-2" v-if="isLoading" />
    </template>
    <div class="flex flex-grow flex-col items-center justify-center py-10" v-else>
      <span class="h-24 w-24 flex items-center justify-center bg-bg-alternate-3 rounded-full">
        <Fundraiser2Icon class="h-8 stroke-2 stroke-current text-text-primary" />
      </span>
      <h4 class="font-medium text-md mt-8 mb-4">No Fundraiser</h4>
      <p class="text-xs font-medium text-center">
        You have not saved any fundraiser
      </p>
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import BFundraiserCardSM from '@/components/ui/BFundraiserCardSM';
import { useApi } from '@/cmp-functions/useApi';
import { getLikedFundraiser } from '@/services/api';
import { useInfiniteScroll } from '@/cmp-functions/useInfiniteScroll';

const handlePaginationResponse = (pre, { data }) => {
  if (!data?.data) return pre;
  const { data: fundraisers, metadata } = data;

  return {
    fundraisers: [...pre.fundraisers, ...fundraisers],
    metaData: {
      offset: fundraisers.length + pre.fundraisers.length,
      ...metadata,
    },
  };
};

export default {
  name: 'Saved',

  components: { BFundraiserCardSM },

  props: ['profileId', 'isOwner'],

  setup(props) {
    const [response, fetchLikedFundraiser] = useApi(
      getLikedFundraiser,
      {
        fundraisers: [],
        metaData: { offset: 0, isLastPage: false },
      },
      handlePaginationResponse
    );

    const fetchFundraiserData = () => {
      if (response.data.metaData.isLastPage || response.isLoading) return;
      fetchLikedFundraiser(props.profileId, response.data.metaData.offset);
    };

    useInfiniteScroll(fetchFundraiserData, true);

    const remove = (index) => {
      response.data.fundraisers.splice(index, 1);
    };

    return {
      ...toRefs(response),
      remove,
    };
  },
};
</script>
