<template>
  <div class="px-6 py-4 text-text-alternate-1">
    <h4 class="text-sm">Activity Badges</h4>
    <ul class="flex gap-7 my-4 px-3 justify-between">
      <li v-for="{ id, color, name } in badges" :key="id">
        <div class="relative">
          <BadgeIcon class="h-14" :style="{ fill: color }" />
          <TickIcon
            class="h-4 fill-current top-2 -right-1 absolute"
            style="fill: #017622"
            v-if="id <= badgeNumber"
          />
          <LockSolidIcon class="bottom-1 -right-1 absolute" v-else />
        </div>
        <span class="text-2xs font-medium">{{ name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Badges',

  props: ['badgeNumber'],

  setup() {
    const badges = [
      { id: 1, name: 'Beginner', color: '#E0F2FF' },
      { id: 2, name: 'Explorer', color: '#A4D9FF' },
      { id: 3, name: 'Explorer', color: '#85CBFE' },
      { id: 4, name: 'Oga at the Top', color: '#3AADFF' },
    ];

    return { badges };
  },
};
</script>
