<template>
  <div class="flex flex-col font-semibold px-6">
    <BPageLoader
      v-if="isLoading && !data.fundraisers?.length"
      class="flex-grow"
    />
    <div
      class="flex flex-col items-center py-10 text-text-primary"
      v-else-if="!data.fundraisers?.length"
    >
      <span
        class="h-24 w-24 rounded-full bg-bg-alternate-3 flex items-center justify-center my-6"
      >
        <Fundraiser2Icon class="h-8 stroke-2 stroke-current text-text-primary" />
      </span>
      <h4 class="my-4 text-lg">No Fundraisers</h4>
      <template v-if="isOwner">
        <p class="text-xs font-medium text-center">
          You have not created fundraisers, please tap the button below to start
        </p>
        <router-link
          class="font-bold mx-auto w-full my-6 md:my-8 py-3 xs:py-4 text-center text-btn-text-primary bg-btn-bg-primary inline-block rounded"
          :to="{ name: 'CreateProject' }"
          >Create Fundraiser</router-link
        >
      </template>
      <p class="text-xs font-medium text-center" v-else>
        This user hasn’t created any fundraisers.
      </p>
    </div>
    <div v-else class="py-4 xs:py-5">
      <div class="grid xs:grid-cols-2 gap-5">
        <template v-for="fundraiser in data.fundraisers">
          <BFundraiserCardMD
            v-if="!fundraiser.makePrivate"
            :key="fundraiser.id"
            :fundraiser="fundraiser"
            @like="fundraiser.likes = $event"
          />

        </template>
      </div>
      <BPageLoader v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import BFundraiserCardMD from '@/components/ui/BFundraiserCardMD';
import { useApi } from '@/cmp-functions/useApi';
import { useInfiniteScroll } from '@/cmp-functions/useInfiniteScroll';
import { getUserFundraisers } from '@/services/api';

const handlePaginationResponse = (pre, { data }) => {
  if (!data?.data) return pre;
  const { data: fundraisers, metadata } = data;

  return {
    fundraisers: [...pre.fundraisers, ...fundraisers],
    metaData: {
      offset: fundraisers.length + pre.fundraisers.length,
      ...metadata,
    },
  };
};

export default {
  name: 'Fundraisers',

  components: { BFundraiserCardMD },

  props: ['profileId', 'isOwner'],

  setup(props) {
    const [response, fetchUserFundraisers] = useApi(
      getUserFundraisers,
      {
        fundraisers: [],
        metaData: { offset: 0, isLastPage: false },
      },
      handlePaginationResponse
    );

    const fetchFundraiserData = () => {
      if (response.data.metaData.isLastPage || response.isLoading) return;
      fetchUserFundraisers({
        userId: props.profileId,
        offset: response.data.metaData.offset,
      });
    };

    useInfiniteScroll(fetchFundraiserData, true);
    // fetchUserFundraisers(props.profileId);

    return { ...toRefs(response) };
  },
};
</script>
